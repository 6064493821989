import React from "react";
import CarouselSlider from "../../lib/Carousel";
import "./style.css"; // Importing the updated CSS

const HeroSection = () => {
  const heroSectionContent = [
    {
      title: "Your one-stop solution for Data & Artificial Intelligence",
      subTitle:
        "We use the power of data & AI to build impactful, flexible & scalable solutions that are a breeze to use",
    },
    {
      title: "Enhanced Business Intelligence",
      subTitle:
        "From better data collection to advanced data analytics, we help you in building a data-first culture in your organization",
    },
    {
      title: "Generative AI seamlessly integrated into your workflows",
      subTitle:
        "You can focus on the core of your business, while we help integrate Gen-AI seamlessly into your workflows",
    },
  ];

  return (
    <div className="hero-wrapper">
      <CarouselSlider>
        {heroSectionContent.map(({ title, subTitle }, index) => (
          <div key={index} className="hero-content">
            <label className="hero-label">{title}</label>
            <p className="hero-subLabel">{subTitle}</p>
          </div>
        ))}
      </CarouselSlider>
    </div>
  );
};

export default HeroSection;
